import React from 'react'

const Logo = props => {
  return (
    <svg
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="129.5" cy="97.5" r="70.5" fill="#F17E01" fillOpacity="0.1" />
      <path
        d="M160.883 82.3445H74.67C72.3962 82.3445 70.5527 84.188 70.5527 86.462V192.86C70.5527 195.134 72.3962 196.978 74.67 196.978H160.883C163.157 196.978 165.001 195.134 165.001 192.86V86.462C165 84.188 163.157 82.3445 160.883 82.3445Z"
        fill="#FCCC93"
      />
      <path
        d="M160.883 79.9265H74.67C72.3962 79.9265 70.5527 81.7702 70.5527 84.0441V190.442C70.5527 192.716 72.3962 194.56 74.67 194.56H160.883C163.157 194.56 165.001 192.716 165.001 190.442V84.0441C165 81.7702 163.157 79.9265 160.883 79.9265Z"
        fill="#FCCC93"
      />
      <path
        d="M157.332 87.2427H78.2246V187.7H157.332V87.2427Z"
        fill="#EBF0F3"
      />
      <path
        d="M157.332 84.8608H78.2246V185.318H157.332V84.8608Z"
        fill="white"
      />
      <path
        d="M143.782 77.0222C143.331 75.0681 141.59 73.6843 139.584 73.6843H125.46C125.461 69.4404 122.02 66 117.776 66C113.532 66 110.092 69.4404 110.092 73.6843H95.9674C93.9622 73.6843 92.2216 75.0681 91.7696 77.0222L88.873 89.5489H146.679L143.782 77.0222ZM117.776 77.3777C115.736 77.3777 114.083 75.7242 114.083 73.6843C114.083 71.6445 115.736 69.991 117.776 69.991C119.816 69.991 121.47 71.6445 121.47 73.6843C121.47 75.7242 119.816 77.3777 117.776 77.3777Z"
        fill="#DA7481"
      />
      <path
        d="M103.754 116.371H84.2188V96.8352H103.754V116.371ZM87.7911 112.799H100.182V100.407H87.7911V112.799Z"
        fill="#FDDFA5"
      />
      <path
        d="M130.141 98.908H108.404V102.311H130.141V98.908Z"
        fill="#E1E6E9"
      />
      <path
        d="M151.878 110.898H108.404V114.301H151.878V110.898Z"
        fill="#E1E6E9"
      />
      <path
        d="M103.754 144.856H84.2188V125.32H103.754V144.856ZM87.7911 141.284H100.182V128.892H87.7911V141.284Z"
        fill="#FDDFA5"
      />
      <path
        d="M130.141 127.392H108.404V130.794H130.141V127.392Z"
        fill="#E1E6E9"
      />
      <path
        d="M151.878 139.383H108.404V142.785H151.878V139.383Z"
        fill="#E1E6E9"
      />
      <path
        d="M103.754 173.341H84.2188V153.805H103.754V173.341ZM87.7911 169.769H100.182V157.377H87.7911V169.769Z"
        fill="#FDDFA5"
      />
      <path
        d="M130.141 155.876H108.404V159.279H130.141V155.876Z"
        fill="#E1E6E9"
      />
      <path
        d="M151.878 167.862H108.404V171.265H151.878V167.862Z"
        fill="#E1E6E9"
      />
      <path
        d="M92.8268 111.734L87.9688 106.015L89.7826 104.473L93.1853 108.478L104.864 99.6584L106.301 101.558L92.8268 111.734Z"
        fill="#34A647"
      />
      <path
        d="M92.8268 139.097L87.9688 133.38L89.7826 131.838L93.1853 135.841L104.864 127.023L106.301 128.923L92.8268 139.097Z"
        fill="#34A647"
      />
    </svg>
  )
}

export default Logo
